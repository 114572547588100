import cmfApi from "../index";

const API_URL = "/votes/current?receiver_id=";

function getVotes(token, id) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};


	const URL = id
		? `${API_URL}${id}`
		: API_URL;

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

function listMasiveVotes(params) {
	let token = localStorage.getItem('ru_token')
	if(token) {
		token = JSON.parse(token)
	}
	const config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token.token}`
		},
		params: params
	};
	let endpoint = `votes/masive`
	return new Promise(function (resolve, reject) {
		cmfApi.get(endpoint, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		)
	});
}

export { getVotes, listMasiveVotes };
