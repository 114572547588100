<script>
export default {
	props: {
		text: {
			required: true,
			type: String,
		},
		textBold: {
			required: false,
			type: String,
		},
	},
};
</script>

<template>
	<h1 class="text-2xl text-center mb-3 md:mb-0 md:mr-3">
		<span class="t">{{text}} </span>
		<span class="b blue block lg:inline-block">{{textBold}}</span>
	</h1>
</template>

<style lang="scss" scoped></style>
