import axios from "axios";
import cmfApi from "../index";
const API_URL = "/culture/elements";

function deleteCulture(token, id) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return new Promise(function (resolve, reject) {
		cmfApi.delete(`${API_URL}/${id}`, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}
function cancelMasiveVote(params) {
	let token = localStorage.getItem('ru_token')
	if(token) {
		token = JSON.parse(token)
	}
	const config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token.token}`
		},
		params: params
	};
	let endpoint = `votes/masive/cancel`
	debugger
	return new Promise(function (resolve, reject) {
		cmfApi.post(endpoint,{},config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		)
	});
}
export { deleteCulture, cancelMasiveVote };
