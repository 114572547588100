<script>
export default {
	props: {
		text: {
			required: true,
			type: String,
		},
		action: {
			required: true,
			type: String,
		},
		name: {
			required: false,
			type: String,
		},
		textBold: {
			required: false,
			type: String,
		},
	},
};
</script>

<template>
	<h2 class="w-full flex-none text-center text-2xl mb-6">
		<span class="t block md:inline-block">{{ action }} </span
		><span class="b"> {{ text }} {{ textBold }}</span>
		<span class="green block" v-capitalizeWords="name"></span>
	</h2>
</template>

<style lang="scss" scoped></style>
