<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";
import FormTitle from "@/components/FormTitle.vue";
import AdminTitle from "@/components/AdminTitle.vue";
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect'
import {createMasiveVotes} from '@/api/votes/create'
import {listMasiveVotes} from '@/api/votes/get'
import {cancelMasiveVote} from '@/api/votes/delete'
import Pagination from "@/components/Pagination.vue";
export default {
	components: {
		SecondaryButton,
		FormTitle,
        AdminTitle,
        DatePicker,
        Multiselect,
        Pagination
	},
	data() {
		return {
			listed: false,
			showForm: false,
			action: "Agregar",
            cultures: [],
            users: [],
            users_query: '',
            isLoadingUsers: false,
            selectedUser: null,
			form: {
                type: 'send',
				id: null,
				title: '',
                emitter_code: '',
				messages: [{
                    id: Date.now(),
                    content: '',
                    culture_element_id: null
                }],
				date: '',
                time: ''
			},
            formErros: {
                title: '',
                emitter_code: '',
                messages: '',
                cultures: '',
                time: '',
                date: ''
            },
            fileobject: null,
            pageParams: {
                page: 1,
                size: 15
            },
            masive_votes: {items: [], pages: 0, page: 1},
            status: {
                pending: 'Pendiente',
                processing: 'Procesando',
                completed: 'Completado',
                canceled: 'Cencelado',
            },
            types: {
                test: 'Prueba',
                send: 'Masivo',
            }
		};
	},
	async created() {
		this.SET_LOADING(true);
		this.listed = false;
		this.loadCultures();
        await this.loadMasiveVotes()
	},
	computed: {
       
        getTimeValue() {
            // if(this.form.date) {

            // } 
            let vm = this
            let date = vm.$moment()
            if(this.form.date) {
                let formDate = vm.$moment(this.form.date)
                // Verificar si son el mismo día
                let sameDay = formDate.isSame(date, 'day');

                if(!sameDay)  {
                    date = formDate
                } else {
                    date = vm.$moment().add(15, 'minutes')
                }
            } else {
                date =  vm.$moment().add(15, 'minutes')
            }
            return date.toDate()
        },
		...mapState("loading", ["isLoading"]),
		...mapState("faqsStore", ["faqs", "error"]),
        ...mapGetters({
            readCultures: "getCultures/readCultures",
            //READ_USERS
			readUsers: "searchUser/readUsers",
        })
	},
	methods: {
		...mapMutations("loading", ["SET_LOADING"]),
		...mapMutations("faqsStore", ["SET_FAQ_ITEM", "SET_ERROR"]),
		...mapMutations({
            setGetError: "getCultures/SET_ERROR",
            //SET_USER_ID
			setDeleteId: "deleteUser/SET_ID",
            //SET_FILTERS_ID
            setUserFilter: "searchUser/SET_FILTER",

        }),

        ...mapActions({
			getCultures: "getCultures/getCultures",
			getUsers: "searchUser/getUsers",
		}),
        loadSearch: _.debounce(function (users_query) {
			if (users_query.length > 3) {
                this.isLoadingUsers = true
				this.clearErrors();
				// this.users = [];
				this.listed = false;
				// this.setLoading(true);
				this.setUserFilter(users_query);
				this.getUsers()
					.then((response) => {
						if (response) {
							if (this.readUsers.length > 0) {
								this.users = this.readUsers || [];
							}
						} else {
                            this.users = [];
                        }
					})
					.finally(() => {
						this.listed = true;
                        this.isLoadingUsers = false
						// this.setLoading(false);
					});
			} 
		}, 300),
		async cancel(id) {
            let params = {id}
            this.$swal({
				title: "¿Desea eliminar este reconocimiento masivo?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then(async (result) => {
				if (result.isConfirmed) {
                    this.SET_LOADING(true)
                    try {
                        let result = await cancelMasiveVote(params)
                        this.loadMasiveVotes()
                        this.$swal({
                            icon: "success",
                            title: `Se elemino reconocimiento masivo.`,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } catch (error) {
                        alert(error.message)
                        
                    }
                   
				}
			});
        },
		loadCultures() {
			this.listed = false;
			this.clearErrors();
			this.getCultures().then((result) => {
				if (result) {
					this.cultures = this.readCultures;
				}
				this.listed = true;
                this.SET_LOADING(false);
			});
		},

        async loadMasiveVotes() {
            this.listed = false
            this.SET_LOADING(true)
            try {
                let results = await listMasiveVotes(this.pageParams)
                results.items = results.items.map(i => {
                    i.emitter = JSON.parse(i.emitter)
                    i.messages = JSON.parse(i.messages)
                    return i
                })
                debugger
                this.$set(this, 'masive_votes', results)
                debugger
            } catch (error) {
                console.log(error.message)
            }
            this.SET_LOADING(false)
        },
        
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		toggleForm(type = 'send') {
			this.showForm = !this.showForm;
            this.form.type = type
		},
		async loadFaqs() {
			await this.faqsActions("getFaqs");
			this.listed = true;
			this.SET_LOADING(false);
		},
		update(faq) {
			this.SET_ERROR(null);
			this.toggleForm();
			this.action = "Actualizar";
			for (let f in this.faq) {
				this.faq[f]["value"] = faq[f];
			}
		},
		remove(id) {
			this.SET_ERROR(null);
			this.SET_LOADING(true);

			this.$swal({
				title: "¿Desea eliminar esta Pregunta Frecuente?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.SET_FAQ_ITEM({ id });
					this.faqsActions("deleteFaq").then(() => {
						if (!this.error) {
							this.loadFaqs();
							this.$swal({
								icon: "success",
								title: `La Pregunta Frecuente ha sido eliminado correctamente.`,
								showConfirmButton: false,
								timer: 3000,
							});
							this.clear();
							if (this.showForm) this.toggleForm();
						}
					});
				}
			});

			this.SET_LOADING(false);
		},
        clearFromErros() {
            this.formErros = {
                title: '',
                emitter_code: '',
                messages: '',
                cultures: '',
                time: '',
                date: ''
            }
        },
        validateForm() {
            let valid = true
            this.clearFromErros()
            let setInvalid = () => valid = false
            if(!this.form.title) {
                this.formErros.title = 'Es requerido'
                setInvalid()
            }
            if(!this.selectedUser) {
                this.formErros.emitter_code = 'Es requerido'
                setInvalid()
            }
            if(!this.fileobject) {
                this.formErros.fileobject = 'Es requerido'
                setInvalid()
            }
            let messagesFail = this.form.messages.filter(m => !m.content )

            if(messagesFail.length) {
                this.formErros.messages = 'Definir correctamente los mensajes'
                setInvalid()
            }
            let cultureFail = this.form.messages.filter(m => !m.culture_element_id )

            if(cultureFail.length) {
                this.formErros.cultures = 'Definir correctamente los principios de cultura'
                setInvalid()
            }
            if(!this.form.time) {
                this.formErros.time = 'Es requerido'
                setInvalid()
            }
            if(!this.form.date) {
                this.formErros.date = 'Es requerido'
                setInvalid()
            }

            return valid
        },
		async submit() {
			
            let validateForm = this.validateForm()
            if(!validateForm) {
                return 
            }

            this.SET_ERROR(null);
			this.SET_LOADING(true);

            let formData = new FormData()
            let vm = this
            let time = this.$moment(this.form.time, 'hh:mm A').format('HH:mm:ss')
            let send_at = this.$moment(this.form.date).format('YYYY-MM-DD') + ' ' + time
            formData.append('title', this.form.title)
            formData.append('messages', JSON.stringify(this.form.messages))
            formData.append('emitter_code', this.selectedUser.code)
            formData.append('fileobject', this.fileobject)
            formData.append('type', this.form.type)
            formData.append('send_at', send_at)

			try {
                let result = await createMasiveVotes(formData)
                this.toggleForm();
                this.$swal({
                    icon: "success",
                    title: `Reconocimiento ${this.types[result.type]} registrado.`,
                    showConfirmButton: false,
                    timer: 3000,
                });
                this.loadMasiveVotes()
            } catch (error) {
                this.$swal({
                    icon: "danger",
                    title: `No pudimos completar el registro de datos.`,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
			this.SET_LOADING(false);
		},
		clear() {
			this.SET_ERROR(null);
			for (let f in this.faq) {
				this.faq[f].value = null;
			}
			this.faq.active.value = true;
			this.action = "Agregar";
		},
        changePage(page) {
			this.pageParams.page=page
            this.loadMasiveVotes()
		},
        addMessage() {
            this.form.messages.push({
                id: Date.now(),
                content: '',
                culture_element_id: this.form.messages[this.form.messages.length - 1].culture_element_id
            })
        },
        clearErrors() {
			this.setGetError(null);
		},
        setEmitter(code) {
            this.form.emitter_code = code
        },
        handleFileUpload(event) {
            // Acceder al archivo seleccionado desde el evento
            const file = event.target.files[0];
            // Guardar el archivo en el estado del componente o realizar otras operaciones necesarias
            this.fileobject = file;
        },
        limitText (count) {
        return `and ${count} other countries`
        },
        removeMesage(index) {
            this.form.messages.splice(index, 1)
        },
        customLabel(user) {
            return `${user.code} | ${user.name}`
        },
        disabledBeforeToday(date) {
            // Obtener la fecha y hora actual
            const today = this.$moment();

            // Obtener la fecha de mañana
            const tomorrow = this.$moment().add(1, 'day').startOf('day');

            // Calcular la diferencia de tiempo entre today y mañana
            const tiempoRestante = this.$moment.duration(tomorrow.diff(today));

            // Obtener las horas restantes
            const diffTime = tiempoRestante.asMinutes();

            // Comprobar si falta 1 hora o menos para mañana
            if (diffTime <= 15) {
                // Retornar la fecha de mañana
                return date < tomorrow.toDate()
            } else {
                let today = this.$moment().startOf('day')
                // Retornar la fecha de hoy
                return date < today.toDate()
            }
            // today.setHours(0, 0, 0, 0);

            // return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
        },
        cloneMasive(m) {
            this.selectedUser = m.emitter
            this.form.title = m.title
            this.form.messages = m.messages
            this.toggleForm('send')
        },
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        notBeforeTodayCurrent(date) {
            let vm = this
            if(this.form.date) {
                let formDate = this.$moment(this.form.date)
                let now = vm.$moment()

                let sameDay = formDate.isSame(now, 'day')
                console.log(sameDay)
                if(sameDay) {
                    let now = vm.$moment().add(15, 'minutes')
                    return date < now.toDate();
                } else {
                    return false
                }
            }
            return false;
        },
	},
};
</script>

<template>
	<div class="main">
        <div class="main-form gradient-gray py-10 px-5 shadow-sm mb-3 border border-gray">
            <div
                class="m-auto relative flex flex-col md:flex-row  items-center "
            >
                <AdminTitle text="Reconocimientos" textBold="Masivos" />
                <!-- <MainLogo class="md:absolute md:right-1/2 transform md:translate-x-1/2" /> -->
                <secondary-button class="ml-auto elektra-bg-green" @click.native="toggleForm('test')" text="Probar envío" />
                <secondary-button @click.native="toggleForm('send')" text="Programar envío" />
            </div>
        </div>

        <div
			v-if="showForm"
			class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
		>
			<div
				class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
			>

				<SecondaryButton
					@click.native="[toggleForm(), clear()]"
					text="x"
					buttonColor="bg-red"
					class="close rounded-full h-10 absolute right-6 top-6"
				/>
				<form-title v-if="this.form.type == 'send'"
					action="Programar"
					text="Reconocimientos"
					textBold="Masivos"
					name=""
				></form-title>
				<form-title v-else-if="this.form.type == 'test'"
					action="Probar"
					text="Reconocimientos"
					textBold="Masivos"
					name=""
				></form-title>
				<!-- DISPLAY FORM -->
				<div class="">
        <!-- <pre>{{ form }}</pre> -->

                    <!-- <pre>{{ form }}</pre> -->
					<div
						class="text-lg mb-3"
						
						ref="labels"
					>
                        <p>Nombre campaña de envío</p>
						<input
							class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
							placeholder="Ejem: Reconocimiento por asistir a el evento"
							v-model="form.title"
							rows="2"
						/>
                        <span
                            v-if="formErros.title"
                            class="block text-sm red col-span-2"
                            >{{ formErros.title }}</span
                        >
					</div>
					<div
						class="text-lg mb-3"
						
						ref="labels"
					>
                        <p>¿Quien envía el roconocimiento?</p>
                        <div>
                            <multiselect v-model="selectedUser" selectLabel="Preciona enter para seleccionar" id="ajax" :custom-label="customLabel" track-by="code" placeholder="Ingresa nombre ó número de colaborador" open-direction="bottom" :options="users" :multiple="false" :searchable="true" :loading="isLoadingUsers" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="loadSearch">
                                <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.code }} | {{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
                                <template slot="clear" slot-scope="props">
                                <div class="multiselect__clear" v-if="selectedUser" @mousedown.prevent.stop="clearAll(props.search)"></div>
                                </template>
                                <span slot="noResult">Oops! No encontramos coincidencias.</span>
                                <span slot="noOptions">No hay datos.</span>
                            </multiselect>
                        </div>
                        <span
                            v-if="formErros.emitter_code"
                            class="block text-sm red col-span-2"
                            >{{ formErros.emitter_code }}</span
                        >
                        <!-- <div class="sarching-user">
                            <input
                                @keyup="loadSearch"
                                class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
                                placeholder="Ingrese Nombre ó número de empleado"
                                v-model="users_query"
                                rows="2"
                            />
                            <ul class="sarching-user__results">
                                <li v-for="(user, index) in users" :key="`user_${index}`" @click="setEmitter(user.code)">
                                    {{ user.code }} | {{ user.name }}
                                </li>
                            </ul>
                        </div> -->
					</div>
                   

                    <div
						class="text-lg mb-3 mt-3"
					>
                        <div v-for="(message, index_message) in form.messages" :key="`message-${index_message}`" class="mt-3">
                            <hr class="border-slate-200	">
                            <div class="px-2 border-red bg-steal-400">
                                <p class="mt-3">Mensaje {{ index_message + 1 }}  <button v-if="form.messages.length > 1" class="" @click="removeMesage(index_message)">
                                    <font-awesome-icon
                                            class="red text-xl  cursor-pointer ml-2"
                                            icon="fas fa-backspace"
                                        />
                                </button> </p>
                                <p class="mt-3">Contenido</p>
                                <textarea 
                                    class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
                                    placeholder="Ejem: Gracias por asistir al evento"
                                    v-model="message.content"
                                    rows="2"
                                />
    
                                <p class="mt-3">Principio de cultura</p>
                                <select 
                                    class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
                                    placeholder="Ejem: Fabrice"
                                    v-model="message.culture_element_id"
                                    rows="2"
                                >
                                <option :value="null" disabled>Selecionar principio</option>
                                <option v-for="(culture, index_culture) in cultures" :key="`culture_${index_culture}`" :value="culture.id">{{ culture.name }}</option>
                            </select>
                            </div>

                        </div>
                        <span
                            v-if="formErros.messages"
                            class="block text-sm red col-span-2"
                            >{{ formErros.messages }} </span
                        >
                        <span
                            v-if="formErros.cultures"
                            class="block text-sm red col-span-2"
                            >{{ formErros.cultures }} </span
                        >
                        <div class="text-center mt-3">
                            <button type="button" class="inline-flex items-center justify-center secondary-button w-auto text-sm text-center text-white b rounded-full px-4 py-2 cursor-pointer flex-none elektra-bg-red" @click="addMessage">Agregar mensaje</button>
                        </div>

                       
					</div>
                    <div class="mt-3">
                        <hr  class="border-slate-200	">
                    </div>
                    <div>
                        <div class="text-center">
                            <p class="mt-3 text-center">Cargar un excel con los detinartarios, <a href="/formato_reconocimiento_masivo.xlsx" download="formato_reconocimiento_masivo.xlsx" class="text-red"> formato compatible <font-awesome-icon
                                @click="remove(f.id)"
                                class=" text-xl  cursor-pointer ml-1"
                                icon="fas fa-file-download"
                            /></a></p>
                            <div class="mt-3">
                                <label class="mt-3">
                                    <input class="none" type="file" accept=".xlsx, .xls" @change="handleFileUpload">
                                    <span type="button" class="inline-flex items-center justify-center secondary-button w-auto text-sm text-center text-white b rounded-full px-4 py-2 cursor-pointer flex-none elektra-bg-green" >Cargar destinatarios
                                        <font-awesome-icon
                                            @click="remove(f.id)"
                                            class="text-white text-xl  cursor-pointer ml-2"
                                            icon="fas fa-file-upload"
                                        />
                                    </span>
                                </label>
                            </div>

                            <span
                                v-if="formErros.fileobject"
                                class="block text-sm red col-span-2"
                                >{{ formErros.fileobject }} </span
                            >
                        </div>
                    </div>
                    <div class="mt-3">
                        <hr  class="border-slate-200	">
                    </div>
                    <div class="flex flex-wrap w-full mt-3 text-lg mb-3 items-center">
                        <div class="w-full">
                            <p>Fecha y hora</p>
                        </div>
                        <div class="mr-3 ">
                            <date-picker
                                v-model="form.date"
                                format="YYYY-MM-DD"
                                type="date"
                                placeholder="Fecha"
                                input-class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"	
                                :disabled-date="disabledBeforeToday"
                                @change="form.time  = ''"
                            ></date-picker>
                        </div>
                        <div class="">
                            <date-picker
                                v-model="form.time"
                                format="hh:mm a"
                                value-type="hh:mm a"
                                type="time"
                                placeholder="Hora"
                                :default-value="getTimeValue"
                                :disabled-time="notBeforeTodayCurrent"
                                input-class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"	
                            ></date-picker>
                        </div>
                        <div class="ml-auto">
                            <SecondaryButton
                            v-if="this.form.type == 'send'"
							:text="'Programar envío'"
							@click.native="submit"
							class="flex-none text-sm"
                            />
                            <SecondaryButton
                                v-else-if="this.form.type == 'test'"
                                :text="'Probar envío'"
                                @click.native="submit"
                                class="flex-none text-sm elektra-bg-green"
                            />
                        </div>
                    </div>
                    <span
                        v-if="formErros.time || formErros.date"
                        class="block text-sm red col-span-2"
                        >{{ formErros.date }} {{ formErros.time }}</span
                    >
					
					<div
						class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
					>
						
						<!-- <SecondaryButton
							@click.native="[toggleForm(), clear()]"
							text="Enviar "
							buttonColor="bg-green"
							class="flex-none mb-3 md:mb-0 md:mr-2"
						/> -->
						
					</div>
				</div>
			</div>
		</div>
        
		<table
			v-if=" masive_votes.items.length > 0"
			id="no-more-tables"
			class="block lg:table table-auto w-full"
		>
			<thead class="block lg:table-header-group elektra-bg-red">
				<tr class="block lg:table-row border border-gray">
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
					>
						Campaña
					</th>
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						
					>
						Emisor
					</th>
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						
					>
						Mensajes
					</th>
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						
					>
						Fecha de envío
					</th>
					<th
						class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
						
					>
						Estado
					</th>
					<th
						class="block lg:table-cell b text-white border-r py-3 px-1"
					>
						Acciones
					</th>
				</tr>
			</thead>
			<tbody class="block md:flex flex-wrap lg:table-row-group">
				<tr
					class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
					:class="
						masive_votes.items.length > 1 ? 'w-full md:w-1/2 lg:w-full' : 'w-full'
					"
					v-for="(m, i) in masive_votes.items"
					:key="i + '_masive'"
				>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell  border-r border-gray-light py-3 px-1"
					>
					<span class="tag mr-1" :class="`tag-${m.type}`">
                        {{ types[m.type] }}
                    </span>	
                    {{ m.title }}
					</td>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell  border-r border-gray-light py-3 px-1"
						
					>
                    {{ m.emitter.code }} | {{ m.emitter.name }}
					</td>
					<td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						
						
					>
                    {{ m.messages.length }} {{ m.messages.length > 1 ? 'mensajes' : 'mensaje' }}
					</td>
                    <td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						
						
					>
                    {{ m.send_at | moment('DD [de] MMMM, YYYY hh:mm A') }} 
					</td>
                    <td
						class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
						
						
					>
                    {{ status[m.status] }} 
					</td>
					<td
						class="flex justify-evenly px-1 items-center py-3 icons lg:table-cell"
					>
						<div class="w-full flex justify-evenly items-center">
							<span
                                v-if="m.type == 'test'"
								class="title-hint"
								aria-label="Programar envío"
							>
								<font-awesome-icon
									@click="cloneMasive(m)"
									class=" hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fas fa-paper-plane"
								/>
								
							</span>
							<span
                                v-else-if="m.type == 'send'"
								class="title-hint"
								aria-label="Clonar para nuevo envío"
							>
								
								<font-awesome-icon 
									@click="cloneMasive(m)"
									class=" hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fas fa-clone"
								/>
							</span>
							<span
								class="title-hint"
								aria-label="Cancelar envío"
							>
								<font-awesome-icon
                                v-if="m.status == 'pending'"
									@click="cancel(m.id)"
									class="red hover:text-white text-3xl md:text-2xl cursor-pointer"
									icon="fas fa-ban"
								/>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span v-else-if="listed" class="block my-3 blue text-center b"
			>No se encontraron Reconocimientos masivos</span
		>
        
        <Pagination
            class="mx-auto mt-3"
            :total-pages="masive_votes.pages"
            :total="masive_votes.total"
            :per-page="masive_votes.size"
            :current-page="masive_votes.page"
            @pagechanged="changePage"
        />

	</div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" >

.multiselect__input{
    height: 38px;
    font-size: 1.125rem;
    background-color: rgb(241, 241, 241);
}
.multiselect__placeholder{
    font-size: 1.125rem;
}
.multiselect__tags{
    height: 38px;
    outline-color: #d8271b !important;
    border: #d8271b;
    background-color: rgb(241, 241, 241);
    border: 1px solid rgb(235, 235, 235);
    padding-top: 0;

}
.multiselect__tag {
  color: rgb(48, 48, 48);
  background-color: rgba(#d8271b, 0.3);
  font-size: 01rem;
}
.multiselect__tag:hover {
  background-color: rgba(var(--vs-warning), 0.3);
}

.multiselect__option--disabled {
  background: purple;
  color: rgba(#d8271b, 1);
  font-style: italic;
}

.multiselect__option--highlight {
  background-color: rgba(#d8271b, 1);
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  height: 1rem;
}

.multiselect__content {
  background: rgb(255, 255, 255);
  color: #444;
  font-size: 0.9rem;
}
.multiselect__single {
    height: 0.8rem;
    font-size: 01rem;
    color: #444;
    height: 38px;
    line-height: 38px;
    font-size: 1.125rem;
    background-color: rgb(241, 241, 241);
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 0.25rem 1.625rem 0.25rem 0.625rem;

  margin-right: 0.625rem;

  line-height: 1;

  margin-bottom: 0.5rem;
}
</style>


<style lang="scss" scoped>
.text-red{
    color: #1f61f0;
}
.none{
    display: none;
}
.sarching-user{
    position: relative;
    &__results{
        width: 100%;
        position: absolute;
        background: #fff;
        border: 1px solid #eee;
        box-shadow:  0 0 2px rgba(0,0,0,0.1);
        border-radius: 3px;
        max-height: 300px;
        overflow: auto;
        z-index: 10;
        
        li{
            padding: 0.25rem 1rem;
            border-bottom: 1px solid #eee;
            &:hover{
                background: #ededed;
                color: #d8271b;
            }
        }
    }
}
.border-slate-200	{
    border-color: #eee;
}

/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 0 "Campaña", 1 "Emisor", 2 "Mensajes", 3 "Envío", 4 "Estatdo";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i + 1}):before {
			content: $field;
		}
	}
}
.tag{
    font-size: 0.8rem;
    background: #ccc;
    padding: 0.15rem 0.25rem;
    border-radius: 0.25rem;
    &-test{
        background-color: #17a54d;
        color: #fff;
    }
    &-send{
        background-color: #da261b;
        color: #fff;
    }
}
</style>
